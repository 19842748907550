import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Thumbnail from "../components/thumbnail";
import Layout from "../components/layout";
import SEO from "../components/seo";

export default ({ uri }) => {
  
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      contentfulListOfProjects(
        id: { eq: "b67429a5-fc50-53c9-9296-95ea21f5822e" }
      ) {
        seo {
          title
          description
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
        index {
          id
          title
          credits
          year
          slug
          color
          type
          media {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          thumbnails {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          description {
            raw
          }
        }
      }
    }
  `);

  let thumbData = [];
  data.contentfulListOfProjects.index.forEach((e) => {
    return e.thumbnails.forEach((t) => {
      thumbData.push({
        thumbnail: t,
        title: e.title,
        color: e.color,
        type: e.type,
        year: e.year,
        slug: e.slug,
      });
    });
  });

  return (
    <Layout uri={uri}>
      <SEO meta={data.contentfulListOfProjects.seo} pathname="projects" />
      <div className="flex-wrap" style={{ fontSize: 0, lineHeight: 0 }}>
        {thumbData.map((t, i) => (
          <Thumbnail thumbnail={t} key={`thumb-${i}`} />
        ))}
      </div>
    </Layout>
  );
};
