import React, { useState } from "react";
import Img from "gatsby-image";

export default ({ thumbnail }) => {
  const [hidden, setHidden] = useState(false);
  const [imageHasLoaded, setImageHasLoaded] = useState(false);

  return (
    <a href={`/${thumbnail.slug}/`} key={``}>
      <div
        className="w-1/2 lg:w-1/3 xl:w-1/3 inline-block group relative "
        style={{ backgroundColor: `#${thumbnail.color}` }}
        onMouseEnter={() => setHidden(true)}
        onMouseLeave={() => setHidden(false)}
      >
        {imageHasLoaded && (
          <div className="hidden sm:inline-block">
            <div className="absolute text-center w-full top-0 text-2xl">
              <div className="py-10 px-10 uppercase text-2xl">
                {thumbnail.title}
              </div>
            </div>

            <div className="absolute text-center w-full bottom-0 text-sm">
              <div className="py-10 px-6 lg:px-16 capitalize">
                {thumbnail.type}
                <br />
                {thumbnail.year}
              </div>
            </div>
          </div>
        )}

        <div
          className={`overflow-hidden active:opacity-100 md:active:opacity-0 ${
            hidden ? "opacity-0" : "opacity-100 md:opacity-100"
          }`}
        >
          <Img
            fluid={thumbnail.thumbnail.fluid}
            className=""
            onLoad={() => setImageHasLoaded(true)}
            fadeIn={false}
          />
        </div>
      </div>
    </a>
  );
};
